<template>
  <v-navigation-drawer
    @input="toggleDrawer"
    :value="colorDrawnerIsOpened"
    class="pt-3 pa-5 pr-0 custom-scrollbar"
    width="450px"
    temporary
    right
    fixed
  >
    <v-container
      class="pa-0 pr-4"
      fluid
    >
      <v-row class="mb-2">
        <v-col>
          <h1 class="text-h5 primary--text">Agrupar Cores</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            v-model="colorName"
            label="Nome da cor agrupada"
            rounded
            dense
            hide-details
          />
          <v-select
            class="mt-4"
            v-model="getColorsSelected"
            :items="getColorsSelected"
            label="Cores Selecionadas"
            item-value="id"
            item-text="name"
            chips
            multiple
            disabled
            :dense="!getColorsSelected.length"
            outlined
            rounded
          />
          <v-row align="center">
            <v-col cols="9">
              <v-text-field
                outlined
                v-model="color"
                label="Hexadecimal da cor"
                rounded
                dense
                hide-details
              />
            </v-col>
            <v-col cols="3">
              <span
                class="color"
                :style="{
                  'background-color': color,
                }"
              ></span>
            </v-col>
          </v-row>
          <v-btn
            class="mt-4"
            color="primary"
            rounded
            @click.prevent="groupColors"
          >
            Agrupar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      value: [],
      color: '#000000',
      mode: 'hexa',
      colorName: '',
    }
  },
  computed: {
    ...mapGetters('colors', ['colorDrawnerIsOpened', 'getColorsSelected']),
  },
  methods: {
    ...mapActions('colors', ['handleColorDrawner']),
    toggleDrawer(e) {
      this.handleColorDrawner({ isOpened: e })
    },
    groupColors() {
      if (this.colorName && this.getColorsSelected.length) {
        this.$emit('setColorsSelected', {
          hexadecimal: this.color,
          colorsSelected: this.getColorsSelected,
          colorName: this.colorName,
        })
        this.colorName = ''
        this.color = '#000000'
      }
    },
    mounted() {
      this.value = this.getColorsSelected
    },
  },
}
</script>

<style lang="scss">
.custom-scrollbar {
  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}

.color {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 10px;
  margin-left: 10px;
  border: 1px solid #000;
}
</style>
